export enum ETimeFrequency {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  CUSTOM = 'CUSTOM',
  ONCE = 'ONCE',
}

export enum EFileType {
  PDF = 'pdf',
  DOCX = 'docx',
  PPTX = 'pptx',
  CSV = 'csv',
  IMAGE = 'image',
  XLSX = 'xlsx',
}

export enum EScheduleType {
  REPORT = 'Report',
  EXPORT = 'Export',
  EXCEL_REPORT = 'Excel report',
}

export enum EMimeType {
  PDF = 'application/pdf',
  CSV = 'text/csv',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

export enum EScheduleLastRunStatus {
  SUCCESS = 'Success',
  LIMIT_REACHED = 'Limit reached',
  ERROR = 'Error',
  ATTACHMENT_PREPARATION_ERROR = 'Attachment preparation error',
  SENDING_EMAIL_ERROR = 'Sending email error',
  IN_PROGRESS = 'In progress',
}

export enum EAttachmentStatus {
  SUCCESS = 'Success',
  PENDING = 'Pending',
  ERROR = 'Error',
}

export enum EDeliveryMethod {
  EMAIL = 'email',
  DOWNLOAD = 'download',
  NOTIFICATION = 'notification',
}

export enum ErrorCodes {}

export enum EScheduleAttachmentDownloadStatus {
  DOWNLOADED = 'downloaded',
  EXPIRED = 'expired',
  AVAILABLE = 'available',
}
