import { ItemDto, ObjectDto } from '@gucci/api-interfaces';

export const WORKBOOK_THEME_SHOWCASE_ROOT_CONTAINER_DATA_OVERRIDES = {
  databaseId: 'DUMMY_DB_ID',
};

const getOverriddenObjectEntity = (objectEntity: ObjectDto): ObjectDto => ({
  ...objectEntity,
  databaseId: WORKBOOK_THEME_SHOWCASE_ROOT_CONTAINER_DATA_OVERRIDES.databaseId,
  properties: {
    ...objectEntity.properties,
    children: (objectEntity.properties.children as ItemDto[]).map((child: ItemDto) => ({
      ...child,
      objectEntity: getOverriddenObjectEntity(child.objectEntity),
    })),
  },
});

let SAFE_WORKBOOK_THEME_SHOWCASE_ROOT_CONTAINER_DATA: ObjectDto;

void import('./workbook-theme-showcase-root-container-data.json').then((module) => {
  const data = module.default;

  SAFE_WORKBOOK_THEME_SHOWCASE_ROOT_CONTAINER_DATA = {
    ...data,
    workbookId: null,
    itemId: null,
    properties: {
      children: [],
      ...data?.properties,
      themeSheetBackground: null,
      backgroundColor: null,
    },
  };
});

export const getWorkbookThemeShowcaseRootContainer = () => ({
  ...getOverriddenObjectEntity(SAFE_WORKBOOK_THEME_SHOWCASE_ROOT_CONTAINER_DATA),
});
