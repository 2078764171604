export enum EMessageAppIntegration {
  SLACK_WEB_HOOK = 'SlackWebHook',
  SLACK_APP = 'SlackApp',
  TEAMS_WEB_HOOK = 'TeamsWebHook',
  TEAMS_APP = 'TeamsApp',
}

export enum IntegrationOption {
  SLACK = 'SLACK',
  TEAMS = 'TEAMS',
}
