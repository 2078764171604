export enum DaedalusEventType {
  SELECTION_APPLIED = 'SELECTION_APPLIED',
  SELECTION_REMOVED = 'SELECTION_REMOVED',
  REFRESH_DATA = 'REFRESH_DATA',
  MANUAL_DATA_UPDATED = 'MANUAL_DATA_UPDATED',
  OBJECT_VISIBILITY_CHANGE = 'OBJECT_VISIBILITY_CHANGE',
  REFERENCES_CHANGED = 'REFERENCES_CHANGED',
  GROUPED_DIMENSIONS_CHANGED = 'grouped-dimensions-changed',
}

/**
 * @deprecated use DaedalusEventType
 */
export enum LegacyDaedalusEventType {
  REFRESH_DATA = 2,
}

export interface DaedalusEvent<T> {
  type: DaedalusEventType | string;
  value: T;
  id?: string;
}
